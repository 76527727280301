import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
//import { withPrefix, Link } from "gatsby"
import { withPrefix } from "gatsby"
//import facebook from '../../img/social/facebook.svg'
//import instagram from '../../img/social/instagram.svg'
//import twitter from '../../img/social/twitter.svg'


export default () => (
  <Layout>
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">

          <div className="section">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          Add Product
          </h2>
          <p>Please enter the product information below.</p>







          <div align="center">
          <Helmet>
            <script src={withPrefix('subscribe-jotform.js')} type="text/javascript" />
          </Helmet>

          <div align="center">
          <iframe title="Add Product Form" class="airtable-embed" src="https://airtable.com/embed/shrJEVfcAJMnnjiIp?backgroundColor=blue&viewControls=on" frameborder="0" onmousewheel="" width="600" height="600" style={{background: "white", border: "0px solid #ccc"}}></iframe>
          </div>


          </div>



          </div>

        </div>

      </div>
    </div>
</section>
  </Layout>
)
